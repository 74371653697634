import { useStaticQuery, graphql } from "gatsby";

export const useGetAllSKUsQuery = () => {
  const data = useStaticQuery(graphql`
    {
      skus: allAirtable(
        filter: { data: { status: { eq: "Available" } } }
        sort: { fields: data___SKU, order: DESC }
      ) {
        nodes {
          data {
            title
            artist
            SKU
            ISBN
            categories
            tags
            RRP
            size
            description
            format
            pages
            edition_size
            cover {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.skus.nodes;
};
