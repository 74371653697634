import React, { useMemo, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  TableContainer,
  TableDropDown,
  Section,
  SectionContent,
  Seo
} from "../components";
import Layout from "../components/Layout";

// hooks
import { useGetAllSKUsQuery } from "../hooks/useGetAllSKUs";

const MOBILE_COLUMNS = [
  {
    Header: () => null,
    id: "expander", // 'id' is required
    width: 30,
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()} className="table-arrow">
        {row.isExpanded ? "⬇" : "⮕"}
      </span>
    )
  },
  {
    Header: "SKU",
    width: 80,
    accessor: "data.SKU"
  },
  {
    Header: "Title",
    accessor: "data.title"
  }
];

const TABLET_COLUMNS = [
  {
    Header: () => null,
    id: "expander", // 'id' is required
    width: 50,
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()} className="table-arrow">
        {row.isExpanded ? "⬇" : "⮕"}
      </span>
    )
  },
  {
    Header: "SKU",
    width: 100,
    accessor: "data.SKU"
  },
  {
    Header: "Title",
    width: 400,
    accessor: "data.title"
  },
  {
    Header: "Artist",
    width: 200,
    accessor: "data.artist"
  }
];

const DESKTOP_COLUMNS = [
  ...TABLET_COLUMNS,
  {
    Header: "ISBN",
    width: 200,
    accessor: "data.ISBN"
  }
];

const Catalogue = () => {
  const data = useGetAllSKUsQuery();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });
  const isMobile = useMediaQuery({ query: "(max-width: 764px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 765px) and (max-width: 1224px)"
  });

  const columns = useMemo(() => DESKTOP_COLUMNS, []);
  const columnsTablet = useMemo(() => TABLET_COLUMNS, []);
  const columnsMobile = useMemo(() => MOBILE_COLUMNS, []);

  // desktop sub component
  const renderRowSubComponent = row => (
    <TableDropDown product={row.original.data} />
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <Layout>
      <Seo title="Catalogue" />
      <Section title="Catalogue">
        <SectionContent>
          {isMobile && (
            <TableContainer
              columns={columnsMobile}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          )}
          {isTablet && (
            <TableContainer
              columns={columnsTablet}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          )}
          {isDesktop && (
            <TableContainer
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
            />
          )}
        </SectionContent>
      </Section>
    </Layout>
  );
};

export default Catalogue;
